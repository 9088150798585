import { useState, useEffect } from "react";

import { Link } from "react-router-dom"

export default function NavBar() {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const onScroll = () => {
            const isScrolled = window.scrollY > 50;
            setIsScrolled(isScrolled);
        };
        window.addEventListener("scroll", onScroll);
        return () => {
            window.removeEventListener("scroll", onScroll);
        }
    }, []);

    return (
        <div className={`nav-bar ${isScrolled ? "scrolled" : ""}`}>
            <Link className="nav-link" to='/home'>Home</Link>
            <Link className="nav-link" to='/blog'>Blog</Link>
            <div className="nav-spacer"></div>
            <Link className="nav-link" to='/school'>School</Link>
            <Link className="nav-link" to='/work'>Work</Link>
            <Link className="nav-link" to='/projects'>Projects</Link>
        </div>
    );
}