import NavBar from "./NavBar";
import Footer from "./Footer";

export default function SchoolPage() {
    return (
        <div style={{ position: 'relative', minHeight: '100vh' }}>
            <NavBar />
            <h1 className="top">I am attending Barrett Honors College at Arizona State University</h1>
            <div className="school-progress-grid">
                <div className="course-container">
                    <h2>Key Undergrad Courses</h2>
                    <ul>
                        <li>Data Structures and Algorithms</li>
                        <li>Computer Organization and Assembly</li>
                        <li>Operating Systems</li>
                        <li>Software Engineering</li>
                        <li>Computer Networks</li>

                    </ul>
                </div>
                <div className="course-container">
                    <h2>Key Graduate Courses</h2>
                    <ul>
                        <li>Advanced Network Security</li>
                        <li>Software Analysis and Design</li>
                        <li>Database Management</li>
                        <li>Machine Learning</li>
                        <li>Data Visualization</li>

                    </ul>
                </div>
                <div className="more-information-container">
                    <h2>More Information</h2>
                    <h3>GPA: 3.48</h3>
                    <h3>B.S. Computer Science May 2025</h3>
                    <h3>M.S. CS (Networks) Thesis May 2026</h3>
                    <h4>Teaching Assistant for Programming Languages / Compiler Development</h4>
                    <h4>Member of Software Developers Association (SODA)</h4>

                </div>
            </div>

            <Footer />
        </div>
    )
}