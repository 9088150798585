import NavBar from "./NavBar";
import Footer from "./Footer";
import MealMapLogo from "./images/logo512.png";
import MNISTCover from "./images/MNISTCover.png";
import VarsityLogo from "./images/VarsityMedia.png";
import ResearchPicture from "./images/IMG_5702.jpg";

const projects = [
    {
        title: "FPGAs in Network Research",
        description: "Used Xilinx FPGA's to accelerate error detection with a custom network",
        note: "Authored a paper on the research",
        link: "",
        image: ResearchPicture,
        alt: "Presentation",
    }, {
        title: "Varisity Sports Show App",
        description: "An IOS application that allows users to view live broadcasts from the vasirty media team",
        note: "Capstone Project for ASU",
        link: "",
        image: VarsityLogo,
        alt: "VarsityMedia",
    }, {
        title: "Meal Maps",
        description: "A full stack web application that makes planning and preparing food much easier",
        note: "Information Regarding Development and Release can be found in the blog",
        link: "https://mealmaps.us",
        image: MealMapLogo,
        alt: "mealmap.us",
    },
    {
        title: "Handwritten Number Recognizer",
        description: "A demonstration of a Neural Network that can sort handwritten numbers",
        note: "Numbers displayed are separate from training data",
        link: "https://github.com/efrink-dev/MNISTSorting",
        image: MNISTCover,
        alt: "NeuralNet",
    },
];

export default function ProjectPage() {
    return (
        <div style={{ position: 'relative', minHeight: '100vh' }}>
            <NavBar />
            <h1 className="top">My Projects</h1>
            {projects.map((project, index) => (
                <a key={index} className="project-card" href={project.link}>
                    <div className="project-thumbnail">
                        <img src={project.image} alt={project.alt}></img>
                        <h2>{project.title}</h2>
                    </div>
                    <div className="project-description">
                        <h3>{project.description}</h3>
                        <h4>{project.note}</h4>
                    </div>
                </a>
            ))}
            <Footer />
        </div>
    );
}
