import NavBar from "./NavBar";
import canyonPic from "./images/IMG_7914.jpg";
import TickerFeed from "./TickerFeed";
import emailLogo from "./images/email-logo.png";
import linkedinLogo from "./images/linkedin-logo.png";
import Footer from "./Footer";
export default function HomePage() {
    return (
        <div style={{ position: 'relative', minHeight: '100vh' }}>
            <NavBar />
            <div className="blurb-grid">
                <div className="blurb-img">
                    <img className='canyon-pic' src={canyonPic} alt="Ethan Frink" />
                </div>
                <div className="blurb-content">
                    <h1>Ethan Frink</h1>
                    <h2>Computer Science Research and Development</h2>
                    <br />
                    <h3>A passionate detail oriented developer that loves to create, explore, and solve problems using world changing Technologies</h3>
                    <br />
                    <h3>Contact me:  </h3><a href="mailto: efrink29@gmail.com"><img className="contact-logo" src={emailLogo} alt="efrink29@gmail.com"></img></a>
                    <a href="https://www.linkedin.com/in/ethan-frink-asu/"><img className="contact-logo" src={linkedinLogo} alt="Linkedin"></img></a>

                </div>
            </div>
            <h1 className='section-header'>Skills / Technologies</h1>
            <TickerFeed />

            <div className="tech-list">
                <h2>I have valuable experience in a variety of languages and technologies. Here's what I am currently using:</h2>
                <div className="tech-list-item">
                    <h3>Xilinx: </h3>
                    <p>I have been exploring the capabilities of Xilinx FPGAs for high-performance computing and hardware acceleration. My work involves designing and implementing custom hardware solutions to optimize computational tasks.</p>

                </div>
                <div className="tech-list-item">
                    <h3>Fabric Midscale Testbed: </h3>
                    <p>Fabric has been the network testbed of choice for me for the past year. They allow me to run highly tailored experiments that push network research forward.</p>
                </div>
                <div className="tech-list-item">
                    <h3>React: </h3>
                    <p>React is my go-to front-end framework. I love the flexibility and scalability it provides for building dynamic web applications.</p>
                </div>
                <div className="tech-list-item">
                    <h3>Python: </h3>
                    <p>Python is my favorite language for scripting and automation. I use it for everything from data analysis to web scraping.</p>
                </div>
                <div className="tech-list-item">
                    <h3>Google Cloud Services: </h3>
                    <p>I have experience with Google Cloud Services and have used them to deploy and scale web applications.</p>
                </div>
            </div>
            <Footer />
        </div>
    )
}