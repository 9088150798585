import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";

export default function WorkPage() {
    const jobs = [

        {
            company: "Starship Technologies",
            position: "Robotic Field Assistant",
            dates: "Fall 2021 - Spring 2024",
            responsibilities: [
                "Responsible for managing a fleet of over 40 autonomous robots",
                "Performed 3d mapping on nearly a mile of pathway to expand service area to include 2 new partners",
                "Oversaw client side implementation of new API endpoints that allowed our service to support Tempe food bank",
            ]
        }, {
            company: "Arizona State University",
            position: "Teaching Assistant",
            dates: "Fall 2024 - Present",
            responsibilities: [
                "Lead recitation sessions and office hours to support students in Programming Languages and Compilers",
                "Created weekly presentations that reinforced core concepts with a focus on practical examples",
                "Graded assignments for 280 students"
            ]
        }
    ];

    return (
        <div style={{ position: 'relative', minHeight: '100vh' }}>
            <NavBar />
            <h1 className="top">Work Experience</h1>
            <div className="job-grid">
                {jobs.map((job, index) => (
                    <div className="job-position-container" key={index}>
                        <h2>{job.company}</h2>
                        <h3>{job.position}</h3>
                        <h4>{job.dates}</h4>
                        <div>
                            {job.responsibilities.map((duty, idx) => (
                                <p key={idx}>{duty}</p>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            <Footer />
        </div>
    );
}
